<!-- 文献解读详情页 -->
<template>
  <div id="decipherDetail"> 
    <Header></Header>
    <div class="new-breadcrumb">
      <ScienceBreadcrumb :text="text"></ScienceBreadcrumb>
    </div>
    <div class="medium-box">
      <div id="img-content" class="rich_media_wrp">
        <h1 class="rich_media_title" id="activity-name">
          {{ decipherInfo.title }}
        </h1>
        <div class="news-content" v-html="decipherInfo.content"></div>
      </div>
    </div>
    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>
    
<script>
import api from "../../../api/api";
import Header from "../../components/Header.vue";
import Footer from "../../components/Footer.vue";
import ScienceBreadcrumb from "../../components/ScienceBreadcrumb.vue";
export default {
  name: "decipherDetail",
  props: {},
  components: {
    Header,
    Footer,
    ScienceBreadcrumb,
  },
  data() {
    return {
      text: "文献解读详情",
      decipherInfo: {},
    };
  },
  created() {
    let id = this.$route.params.id;
    // console.log(id);
    this.getDetailsDecipher(id);
  },
  mounted() {},
  watch: {},
  methods: {
    getDetailsDecipher(id) {
      api
        .getDecipherById(id)
        .then((res) => {
          console.log(res.data);
          this.decipherInfo = res.data.models[0];
        })
        .catch((error) => {});
    },
  },
};
</script>
    
<style lang="scss">
#decipherDetail {
  padding-top: 6.5rem;
  .new-breadcrumb {
  
  }
  .medium-box {
    position: relative;
    width: 100%;
    padding: 1.25rem;
    // padding-top: 5rem;
    margin: auto;
    .rich_media_title {
      font-size: 1.375rem;
      // line-height: 1.4;
      margin-bottom: 1rem;
      text-align: center;
    }
    .news-content {
      // white-space: pre-wrap;
      font-family: "Microsoft Yahei";
      font-size: 1.125rem;
      color: #333;
      p {
        line-height: 2;
      }
    }
  }
  .footer {
    background: #f5f5f5;
  }
  .news-content {
    img {
      max-width: 100%;
      display: block !important;
      margin: 10px auto!important;
      width: 100%;
      height: inherit;
    }
    // p {
    //   font-family: "OPlusSans3-Regular";
    // }
    .ql-align-left {
      text-align: left;
    }
    .ql-align-center {
      text-align: center;
    }
    .ql-align-right {
      text-align: right;
    }
  }
}
</style>